// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-mobile {
    display: none;
    @media screen and (max-width: 1140px) {
        padding: 50px 5%;
        display: flex;
        .left-side {
            width: 60%;
            margin-right: 5%;
            a.btn-slideshow {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 35px;
                background: #000;
                width: 185px;
                height: 65px;
                border-radius: 10px;
                transition: 0.3s;
                h5 {
                    font-size: $font-size-16;
                    color: $color-white;
                }
                &:hover {
                    background: #F57F5B;
                    transition: 0.3s;
                }
            }
        }
        .right-side {
            width: 40%;
            border-style: dotted;
            border-width: 2px;
            border-color: $color-2;
            border-radius: 10px;
            padding: 25px;
            h4 {
                font-size: $font-size-16;
                color: $color-1;
                padding-bottom: 15px;
            }
            p, h5 {
                font-size: $font-size-16;
                color: $color-2;
            }
            h5 {
                padding-top: 15px;
            }
        }
        @media screen and (max-width: $size-xs-max) {
            background: $color-1;
            .left-side {
                display: none;
            }
            .right-side {
                width: 100%;
                border-color: $color-white;
                h4 {
                    color: $color-2;
                }
                h5 {
                    color: $color-white;
                }
            }
        }
    }
}

#section-carousel {
    padding: 50px 5%;
    position: relative;
    .main-content {
        .item {
            .col-xs-12.txt {
                padding-left: 0;
                padding-right: 0;
                h4 {
                    font-size: $font-size-16;
                    color: #202124;
                    padding: 15px 0 0 15px;
                }
            }
        }
        .owl-nav {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;
            top: -30px;
            z-index: 1;
            .owl-prev {
                padding-right: 35px;
            }
        }           
    }
}

#section-title-img {
    padding: 50px 12%;
    .section-services {
        .left-side {
            width: 55%;
            margin-right: 50px;
            .bloc {
                position: relative;
                .text {
                    position: absolute;
                    bottom: 50px;
                    padding: 0 35px;
                    h2 {
                        color: $color-white;
                        padding-bottom: 5px;
                    }
                    p {
                        color: $color-white;
                    }
                }
            }
        }
        .right-side {
            width: 30%;
            h5 {
                font-size: $font-size-16;
                padding-top: 10px;
                &.last {
                    line-height: 1.5;
                }
            }
        }
    }
    @media screen and (max-width: 1400px) {
        padding: 50px 5%;
        .section-services {
            .left-side {
                width: 60%;
            }
            .right-side {
                width: 36%;
            }
        }
        @media screen and (max-width: 1100px) {
            h3 {
                text-align: center;
            }
            .section-services {
                flex-direction: column;
                .left-side {
                    width: 65%;
                    margin: 0 auto 35px auto;
                }
                .right-side {
                    width: 65%;
                    margin: auto;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .section-services {
                    .left-side, .right-side {
                        width: 90%;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 25px 5% 50px 5%;
                    h3 {
                        text-align: initial;
                    }
                    .section-services {
                        .left-side, .right-side {
                            width: 100%;
                        }
                        .left-side {
                            .bloc {
                                .text {
                                    position: initial;
                                    padding: 15px 0 0 0;
                                    h2, p {
                                        color: $color-2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* RÉPARATION FISSURE
*******************************************************************************/

#section-services {
    padding: 50px 5%;
    .left-side {
        width: 35%;
        min-width: 510px;
        margin-right: 35px;
        background: #A6ADB464;
        padding: 35px 50px;
        position: relative;
        .border {
            width: 8px;
            background: $color-1;
            height: 640px;
            position: absolute;
            left: 0;
        }
        h2 {
            color: #202124;
            font-size: $font-size-30;
            text-transform: uppercase;
            padding-bottom: 40px;
        }
        .bloc {
            background: $color-1;
            padding: 20px 55px 20px 15px;
            .icon {
                margin-right: 15px;
            }
            .text {
                h3 {
                    font-size: $font-size-20;
                    color: $color-white;
                    text-transform: initial;
                    line-height: 1.2;
                    padding-bottom: 0;
                }
            }
            
        }
        a.btn-slideshow {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
            background: $color-black;
            width: 240px;
            height: 65px;
            border-radius: 10px;
            transition: 0.3s;
            h5 {
                font-size: $font-size-16;
                color: $color-white;
            }
            &:hover {
                background: $color-1;
                transition: 0.3s;
            }
        }
    }
    .right-side {
        width: 65%;
        .section-images {
            padding-top: 42px;
        }
    }
    @media screen and (max-width: 1668px) {
        .right-side {
            p br {
                display: none;
            }
        }
        @media screen and (max-width: 1490px) {
            .left-side {
                h2 {
                    font-size: 1.35rem;
                }
                .bloc {
                    .text {
                        h3 {
                            font-size: 0.95rem;
                        }
                    }
                }
            }
            @media screen and (max-width: 1425px) {
                .left-side {
                    width: 40%;
                }
                @media screen and (max-width: 1225px) {
                    flex-direction: column;
                    .left-side {
                        width: 60%;
                        margin-bottom: 35px;
                        margin-right: 0;
                        .border {
                            height: 570px;
                        }
                    }
                    .right-side {
                        width: 100%;
                    }
                    @media screen and (max-width: 1024px) {
                        .left-side {
                            width: 70%;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            .left-side {
                                width: 80%;
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .left-side {
                                    width: 100%;
                                    min-width: initial;
                                    padding: 35px;
                                    .bloc {
                                        padding: 20px 45px 20px 15px;
                                    }
                                }
                                .right-side {
                                    .section-images {
                                        padding-top: 35px;
                                        flex-direction: column;
                                        .image.pr3 {
                                            padding: 0 0 15px 0;
                                        }
                                    }
                                }
                                @media screen and (max-width: 360px) {
                                    .left-side {
                                        .bloc {
                                            padding: 20px 15px;
                                            h3 br {
                                                display: none;
                                            }
                                        }
                                    }   
                                }       
                            }
                        }
                    }
                }  
            }
        }
    }
}

/*******************************************************************************
* DRAIN FRANÇAIS
*******************************************************************************/

#section-services.drain {
    .left-side {
        .border {
            width: 8px;
            background: #F57F5B;
            height: 585px;
            position: absolute;
            left: 0;
        }
    }
    .right-side {
        .section-images {
            padding-top: 0;
        }
    }
    @media screen and (max-width: $size-md-max) {
        .left-side .border {
            height: 550px;
        }
        @media screen and (max-width: $size-sm-max) {
            .left-side .border {
                height: 525px;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side .border {
                    height: 495px;
                }
            }
        }
    }
}

/*******************************************************************************
* RÉNOVATIONS
*******************************************************************************/

#section-text {
    padding: 50px 5%;
    .left-side {
        width: 35.5%;
        margin-right: 50px;
        h3 {
            color: $color-2;
            font-size: $font-size-44;
            line-height: 1.2;
            padding-bottom: 0;
        }
    }
    .right-side {
        width: 60%;
        p {
            line-height: 2 !important;
        }
    }
    @media screen and (max-width: 1770px) {
        .left-side h3 br {
            display: none;
        }
        @media screen and (max-width: 1455px) {
            .left-side {
                width: 40%;
            }
            @media screen and (max-width: 1220px) {
                flex-direction: column;
                .left-side {
                    width: 100%;
                    margin: 0 0 25px 0;
                }
                .right-side {
                    width: 100%;
                }
            }
        }
    }
}

#section-reno {
    padding: 0 5%;
    .left-side {
        width: 65%;
        .section-images {
            display: grid;
            grid-template-columns: 1fr .738fr;
            grid-gap: 15px;
            align-items: start;
            justify-items: center;
            margin: auto;
            .image {
                width: 100%;
                &:nth-child(2) {
                    grid-row: span 2;
                }
                &:nth-child(3) {
                    grid-row: span 3;
                }
            }
        }
    }
    .right-side {
        width: 40%;
        min-width: 510px;
        margin-left: 15px;
        background: #A6ADB464;
        padding: 35px 50px;
        position: relative;
        margin-bottom: 14px;
        .border {
            width: 8px;
            background: $color-1;
            height: 1275px;
            position: absolute;
            right: 0;
        }
        h2 {
            color: #202124;
            font-size: $font-size-30;
            text-transform: uppercase;
            padding-bottom: 40px;
        }
        .bloc {
            background: $color-1;
            padding: 20px 55px 20px 15px;
            .icon {
                margin-right: 15px;
            }
            .text {
                h3 {
                    font-size: $font-size-20;
                    color: $color-white;
                    text-transform: initial;
                    line-height: 1.2;
                    padding-bottom: 0;
                }
            }  
        }
    }
    @media screen and (max-width: 1825px) {
        .right-side .border {
            height: 1230px;
        }
        @media screen and (max-width: 1730px) {
            align-items: center;
            .right-side .border {
                height: 1190px;
            }
            @media screen and (max-width: 1490px) {
                .right-side {
                    h2 {
                        font-size: 1.35rem;
                    }
                    .bloc {
                        .text {
                            h3 {
                                font-size: 0.95rem;
                            }
                        }
                    }
                }
                @media screen and (max-width: 1425px) {
                    .right-side {
                        width: 40%;
                    }
                    @media screen and (max-width: $size-md-max) {
                        flex-direction: column-reverse;
                        .right-side {
                            width: 65%;
                            margin: 0 0 35px 0;
                            .border {
                                height: 1140px;
                            }
                        }
                        .left-side {
                            width: 80%;
                        }
                        @media screen and (max-width: 1024px) {
                            .right-side {
                                width: 70%;
                            }
                            @media screen and (max-width: $size-sm-max) {
                                .right-side {
                                    width: 90%;
                                    .border {
                                        height: 1090px;
                                    }
                                }
                                .left-side {
                                    width: 100%;
                                }
                                @media screen and (max-width: $size-xs-max) {
                                    .right-side {
                                        width: 100%;
                                        min-width: initial;
                                        padding: 35px 25px;
                                        .border {
                                            height: 1045px;
                                        }
                                        .bloc {
                                            padding: 20px 45px 20px 15px;
                                        }
                                    }
                                    .left-side {
                                        .section-images {
                                            grid-template-columns: 1fr 0.99fr;
                                        }
                                    }
                                    @media screen and (max-width: 360px) {
                                        .right-side {
                                            .bloc {
                                                padding: 20px 15px;
                                                h3 br {
                                                    display: none;
                                                }
                                            }
                                        }   
                                    }       
                                }
                            }  
                        }  
                    }
                }
            }
        }
    }
}

#section-services.reno {
    padding: 50px 5% 75px 5%;
    .left-side {
        padding: 48px;
        margin-right: 15px; 
        .border {
            width: 8px;
            background: #F57F5B;
            height: 630px;
            position: absolute;
            left: 0;
        }
    }
    .right-side {
        .section-images {
            display: grid;
            grid-template-columns: 1fr .59fr;
            grid-gap: 15px;
            align-items: start;
            justify-items: center;
            margin: auto;
            padding-top: 0;
            .image {
                width: 100%;
                &:nth-child(2) {
                    grid-row: span 2;
                }
            }
        }
    }
    @media screen and (max-width: 1450px) {
        flex-direction: column;
        .left-side {
            width: 65%;
            margin: 0 0 15px 0;
        }
        .right-side {
            width: 100%;
        }
        @media screen and (max-width: $size-md-max) {
            .left-side {
                width: 80%;
                .border {
                    height: 605px;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .left-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .left-side {
                        padding: 35px 25px;
                        .border {
                            height: 590px;
                        }
                    }
                    .right-side .section-images {
                        grid-template-columns: 1fr 1fr;   
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 60px 5%;
    h2 {
        font-size: $font-size-24;
        color: $color-2;
        padding-bottom: 60px;
    }
    .realisations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);     
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 50px 5%;
    position: relative;
    .left-side {
        width: 40%;
        margin-right: 5%;
        h2 {
            font-size: $font-size-50;
            color: $color-2;
            padding-bottom: 15px;
        }
    }
    .right-side {
        width: 45%;
        background: $color-2;
        padding: 70px 10%;
        h3, h4, h5 {
            color: #A6ADB4;
        }
        h3 {
            font-size: $font-size-34;
            text-transform: initial;
            padding-bottom: 0;
        }
        h4 {
            font-size: $font-size-20;
            line-height: 2;
        }
        h5 {
            font-size: $font-size-20;
            font-weight: normal;
            line-height: 2;
        }
        .jours-heures {
            padding: 240px 0;
            .jour {
                width: 60%;
            }
        }
    }
    &::before {
        content: '';
        background: $color-1;
        z-index: -1;
        width: 400px;
        height: 1132px;
        position: absolute;
        right: 0;
        top: 0;
        margin-right: 5%;
    }
    @media screen and (max-width: 1750px) {
        .right-side {
            padding: 70px 7%;
        }
        @media screen and (max-width: 1410px) {
            .left-side {
                width: 45%;
            }
            .right-side {
                width: 50%;
            }
            &::before {
                margin-right: 0;
            }
            @media screen and (max-width: 1150px) {
                flex-direction: column;
                .left-side {
                    width: 100%;
                    margin: 0 0 50px 0;
                }
                .right-side {
                    width: 100%;
                    .jours-heures {
                        padding: 75px 0;
                        .jour {
                            width: 40%;
                        }
                    }
                }
                &::before {
                    display: none;
                }
                @media screen and (max-width: $size-xs-max) {
                    .right-side {
                        .jours-heures {
                            .jour {
                                width: 50%;
                            }
                        }
                        .section-courriel h3 {
                            font-size: 1.45rem;
                        }
                    }
                    &::before {
                        display: block;
                        height: 350px;
                        width: 500px;
                        top: initial;
                        bottom: 0;
                    }
                }
            }
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    

}

@media screen and (max-width: $size-xs-max) {
   

}
