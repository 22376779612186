.slideshow-wrap {
    position: relative;
    @media screen and (max-width: $size-xs-max) {
      background: $color-1;
    }
    .caption {
        position: absolute;
        bottom: 65px;
        right: -90px;
        width: 20%;
        transform: translateX(-50%);
        z-index: 100;
        border-style: dotted;
        border-width: 2px;
        border-color: $color-white;
        border-radius: 10px;
        padding: 25px;
        h4 {
          font-size: $font-size-16;
          color: $color-1;
          padding-bottom: 15px;
        }
        p, h5 {
          font-size: $font-size-16;
          color: $color-white;
        }
        h5 {
          padding-top: 15px;
        }
        @media screen and (max-width: 1600px) {
          right: -80px;
          @media screen and (max-width: $size-md-max) {
            right: -60px;
            @media screen and (max-width: 1175px) {
              width: 22%;
              @media screen and (max-width: 1140px) {
                display: none !important;
              }
            }
          } 
        }
    }

    a.tel {
      position: absolute;
      transform: translateX(-50%);
      z-index: 100;
      top: 5%;
      right: 0;
      .phonenumber {
          font-weight: bold;
          font-size: $font-size-30;
          color: $color-white;
      }
      @media screen and (max-width: 1800px) {
        right: -10px;
        @media screen and (max-width: 1600px) {
          right: -15px;
          @media screen and (max-width: 1450px) {
            right: -20px;
            @media screen and (max-width: 1150px) {
              right: -35px;
              @media screen and (max-width: $size-sm-max) {
                top: 10%;
                @media screen and (max-width: $size-xs-max) {
                  top: 5%;
                  right: -65px;
                  .phonenumber {
                    font-size: 2rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .layer-inner {
      left: 43%;
      bottom: 14%;
      position: absolute;
      transform: translateX(-50%);
      z-index: 100;
      width: 48%;
      h3.title {
        color: $color-white;
        line-height: 1.4;
      }
      p {
        color: $color-white;
      }
      a.btn-slideshow {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 35px;
          background: $color-black;
          width: 240px;
          height: 65px;
          border-radius: 10px;
          transition: 0.3s;
          h5 {
            font-size: $font-size-16;
            color: $color-white;
          }
          &:hover {
            background: $color-1;
            transition: 0.3s;
          }
      }
      @media screen and (max-width: 1600px) {
          left: 29.5%;
          @media screen and (max-width: 1500px) {
              bottom: 9%;
              @media screen and (max-width: 1175px) {
                width: 50%;
                @media screen and (max-width: 1140px) {
                  width: 100%;
                  bottom: 20%;
                  left: 54.5%;
                  h3 {
                    font-size: 1.5rem;
                  }
                  p, a.btn-slideshow {
                    display: none;
                  }
              @media screen and (max-width: $size-sm-max) {
                bottom: 10%;
                @media screen and (max-width: $size-xs-max) {
                  left: 48.5%;
                  padding: 0 25px;
                  p, a.btn-slideshow {
                    display: flex;
                  }
                }
              }
            }
          }
        }
      }
    }
    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        		width: 100vw;
        		height: auto;
        		display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                // height: calc(100vw / 480 * 600);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;   
            }
        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
        		}
        		&.active {
          			right: 0;
                left: 0;
          			z-index: 10;
                opacity: 1;
                transition: 1.5s;
        		}
        	}
        	@media screen and (max-width: 480px) {
              &.mobile {
                  display: block;
              }
              &.ecran {
                  display: none;
              }
        	}
      }
}
