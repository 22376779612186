.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            text-align: center;
            font-size: $font-size-50;
            font-weight: bold;
            color: $color-white;
            //text-shadow: 0 0 10px $color-black;
        }
    }
    a.tel {
        position: absolute;
        top: 7%;
        right: 5.4%;
        .phonenumber {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-white;
        }            
    }
}
@media screen and (max-width: 1550px) {
    .module-header {
        &--caption {
            top: 55%;
        }
    } 
    @media screen and (max-width: $size-sm-max) {
        .module-header {
            &--caption {
                top: 60%;
            }
        }
        @media screen and (max-width: $size-xs-max) {
            .module-header {
                &--caption {
                    top: 55%;
                }
            }
        }
    }
}

