.btn {
    background-color: $color-2;
    color: $color-white;
    border: none;
    border-radius: 10px;
    padding: 15px;
    font-weight: bold;
    font-size: $font-size-26;
    width: 100%;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        background-color: $color-1;
        color: $color-2;
    }
}
