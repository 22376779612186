footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;

    .footer-wrapper {
        width: 100%;
        padding: 50px 5% 0 5%;
        @extend .flex;
        @extend .text-color-grey;
        @extend .font-footer;
        .logo {
            width: 15%;
            margin-top: -25px;
        }
        .copyright-logo {
            width: 18%;
            margin-right: 10%;
            .copyright {
                font-weight: normal;
                color: #A6ADB4;
                padding-bottom: 25px;
            }
        }
        .section-menu-footer {
            width: 58%;
            a.link {
                color: #A6ADB4;
                text-transform: uppercase;
                font-weight: bold;
                transition: 0.3s;
                &:hover {
                    color: $color-1;
                    transition: 0.3s;
                }
            }
        }
        @media screen and (max-width: 1745px) {
            .logo {
                width: 12%;
            }
            .copyright-logo {
                width: 20%;
            }
            @media screen and (max-width: 1100px) {
                flex-direction: column;
                align-items: center;
                padding: 50px 5%;
                .logo {
                    width: 100%;
                    margin-top: 0;
                    margin-left: 25px;
                    .img-responsive {
                        margin: auto;
                    }
                }
                .copyright-logo {
                    width: 100%;
                    margin: 35px auto;
                    .copyright {
                        text-align: center;
                    }
                    .mediaweb {
                        display: flex;
                        justify-content: center;
                    }
                }
                .section-menu-footer {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 35px;
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 35px 5%;
                    .logo {
                        margin-left: 10px;
                    }
                    .section-menu-footer {
                        flex-direction: column;
                        text-align: center;
                        .center {
                            margin: 15px 0;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1100px) {
        height: 391px;
        @media screen and (max-width: $size-xs-max) {
            height: 446px;
        }
    }
}
